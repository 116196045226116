import { Component } from '@angular/core';
import { NgSellyConfig } from 'ng-selly.config';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService, UserType} from "../../../selly-shared-module/auth/user.service";

@Component({
  selector: 'fw-links',
  template: require('./links.html'),
  styles: [require('./links.scss')],
})
export class LinksComponent {
  productCode: string;
  isTCM: boolean;
  isProtecth: boolean;
  // isHelvetiaItalia: boolean;

  constructor(activatedRoute: ActivatedRoute, router: Router,
   private userService: UserService,
     private config: NgSellyConfig) {
    activatedRoute.params.subscribe((params: Params) => {
      this.productCode = params['productCode'];
    });
    // se e' il footer di tcm
    this.isProtecth = (router.url).includes('footer:footer/PROTECTH')
    this.isTCM = (router.url).includes('footer:footer/TCM')
   // this.isHelvetiaItalia = (router.url).includes('footer:helvetiaitalia')
  }

  isAgent() {
    return this.userService.isAuthenticated().type === UserType.AGENT;
  }

  goTo(page: any) {
    switch (page) {
      case 'compagnie':
        window.location.href = 'https://www.helvetia.com/it/web/it/chi-siamo/helvetia/helvetia-in-italia/le-compagnie-del-gruppo.html';
        break;
      case 'ciPresentiamoTCM':
        window.location.href = 'https://www.helvetia.com/it/web/it/chi-siamo/helvetia/helvetia-in-italia/le-compagnie-del-gruppo/helvetia-vita.html';
        break;
        case 'ciPresentiamo':
        window.location.href = '/ci_presentiamo(header:header//footer:footer)';
        break;
      case 'agenzia':
        window.location.href = 'https://www.helvetia.com/it/web/it/chi-siamo/helvetia/helvetia-in-italia/canali-distributivi/agenzie.html';
        break;
      case 'privacyTCM':
        window.open('https://www.helvetia.com/it/web/it/common-page/privacy.html', "_blank");
        break;
        case 'sos':
        window.location.href = '/sos/' + this.productCode + '(header:header//footer:footer)';
        break;
      case 'privacy':
        window.open('https://www.helvetia.com/it/web/it/common-page/privacy.html', "_blank");
        break;
      case 'accessibilita':
        window.open(this.config.apiServer + 'public/Dichiarazione_di_Accessibilita_selly_helvetia_it.pdf', "_blank")
        break;
    }
  }



}
