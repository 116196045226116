import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgSellyConfig } from 'ng-selly.config';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { QuotationRequestGuarantee, PolicyHolder, Insurable, Survey, RemoteSelling } from 'src/selly-shared-module/models';

export interface WizardDefinition {
  code: string;
  next: string;
  component?: any;
  loadChildren?: any;
}

export interface WizardMap {
  [productCode: string]: WizardDefinition[];
}

@Injectable()
export class WizardConfigService {
  getWizardMap(): WizardMap {
    return wizardMap;
  }
  getWizardByCode(productCode: string): WizardDefinition[] {
    return wizardMap[productCode];
  }
}

@Injectable()
export class AdobeProductNameService {


  constructor(private http: HttpClient,  private config: NgSellyConfig,){}

  switchProductName(vehicleClass: string) {
    switch (vehicleClass) {
      case 'Vehicle':
        digitalData.product.productInfo.productName = 'auto';
        break;
      case 'Motorbike':
        digitalData.product.productInfo.productName = 'motociclo';
        break;
      case 'Bike':
        digitalData.product.productInfo.productName = 'ciclomotore';
        break;
      default:
        console.warn('product.productInfo.productName non handled');
    }
  }

  switchProductNameCode(vehicleClassCode: string) {
    switch (vehicleClassCode) {
      case 'A':
        digitalData.product.productInfo.productName = 'auto';
        break;
      case 'W':
        digitalData.product.productInfo.productName = 'motociclo';
        break;
      case 'M':
        digitalData.product.productInfo.productName = 'ciclomotore';
        break;
      default:
        console.warn('product.productInfo.productName non handled');
    }
  }

  track(payload: { visitorId: string; touchPoint?: string; productCode: string; packageCode?: string; variables: { [name: string]: string | number | boolean | Object | string[]; }; guarantees: { [name: string]: QuotationRequestGuarantee; }; policyHolder?: PolicyHolder; insured?: Insurable[]; productNode?: string; ageDescription?: string; survey?: Survey; referer?: string; remoteSelling?: RemoteSelling; quotationNumber?: string; asShop?: any; agent?: any; movement?: any; transactionId: string; }): Observable<any> {
    return this.http.post<any>(`${this.config.apiServerK8s}adobe/track/policy-purchase`, payload, {
      }).pipe(
        map(result => result),
        catchError(err => {
      // console.log('caught mapping error and rethrowing', err);
      return throwError(err);
    }))
  }

  returnProductName(vehicleClass) {
    switch (vehicleClass) {
      case 'Vehicle':
        return 'auto';
      case 'Motorbike':
        return 'motociclo';
      case 'Bike':
        return  'ciclomotore';
      default:
        console.warn('product.productInfo.productName non handled');
    }
  }
}

const wizardMap: WizardMap = {
  EASYTRAVEL: [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'step3',
  }, {
    code: 'step3',
    next: 'step4',
  }, {
    code: 'step4',
    next: 'step5',
  }, {
    code: 'step5',
    next: null,
  }],
  'EASYSKI-USER-2': [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'step3',
  }, {
    code: 'step3',
    next: 'step4',
  }, {
    code: 'step4',
    next: 'step5',
  }, {
    code: 'step5',
    next: null,
  }],
  'EASYSKI-4AGENTS': [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'step3',
  }, {
    code: 'step3',
    next: 'step4',
  }, {
    code: 'step4',
    next: 'step5',
  }, {
    code: 'step5',
    next: null,
  }],
  'AFFINITY-AUTO': [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'step3',
  }, {
    code: 'step3',
    next: 'thankyou',
  }, {
    code: 'thankyou',
    next: 'null',
  }],
};
if (process.env.PRE_SELLING) {
  wizardMap['MY_HOME'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: null,
  }];
  wizardMap['MY_HOME-Evaluation-Survey'] = [{
    code: 'step1',
    next: null,
  }];
  wizardMap['GUIDA_SICURA'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: null,
  }];
  wizardMap['GUIDA_SICURA-Evaluation-Survey'] = [{
    code: 'step1',
    next: null,
  }];
  wizardMap['ESERCIZIO_COMMERCIALE'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: null,
  }];
  wizardMap['ESERCIZIO_COMMERCIALE-Evaluation-Survey'] = [{
    code: 'step1',
    next: null,
  }];
  wizardMap['IN_MOVIMENTO'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'thankyou',
  }, {
    code: 'thankyou',
    next: null,
  }];
  wizardMap['IN_MOVIMENTO-Evaluation-Survey'] = [{
    code: 'step1',
    next: null,
  }];
  wizardMap['IN_MOVIMENTO_RCA'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'thankyou',
  }, {
    code: 'thankyou',
    next: null,
  }];
  wizardMap['IN_MOVIMENTO_RCA-Evaluation-Survey'] = [{
    code: 'step1',
    next: null,
  }];
}
if (process.env.TCM) {
  wizardMap['TCM'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'step3',
  }, {
    code: 'step3',
    next: 'step4',
  }, {
    code: 'step4',
    next: null,
  }];
}

if (process.env.PROTECTH) {
  wizardMap['PROTECTH'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'step3',
  }, {
    code: 'step3',
    next: 'step4',
  }, {
    code: 'step4',
    next: 'step5',
  }, {
    code: 'step5',
    next: null,
  }]

  wizardMap['PROTECTH2'] = [{
    code: 'step1',
    next: 'step2',
  }, {
    code: 'step2',
    next: 'step3',
  }, {
    code: 'step3',
    next: 'survey',
  }, {
    code: 'survey',
    next: 'step4',
  }, {
    code: 'step4',
    next: null,
  }]
}

